import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./BookSaleCover.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux"
import { MDBDropdown, MDBSelect, MDBDropdownToggle, MDBDropdownMenu, MDBFormInline, MDBDropdownItem, MDBBtn } from 'mdbreact'
import BookTab from "./BookTab"
import Common from "./Common";
class BookSaleCover extends Component {
  state = {
    books: [],
    subCategory: false
  };

  componentWillReceiveProps() {

  }
  changeSubCategory = () => {
    this.setState({
      subCategory: true
    })
  }

  render() {
    let category

    if (this.props.category) {
      category = this.props.category.filter(ct => ct.title !== "Bok")
    }

    return (
      <main>
        <section className="booksale-cover-section">
          <div className="container">
            <div className="section-header">
              {/* <h2>Books Category</h2> */}
            </div>

            <div className="section-wrappeer">
              <Tabs
                defaultActiveKey={"Book" + 1}
                transition={false}
                id="noanim-tab-example"
              >
                {/* <Tab eventKey="categories" title="Book" className="book-tb">
                  {this.props.books && <BookTab />}
                </Tab> */}




                {category && category.map(ct => {
                  console.log(ct.id)
                  return (



                    <Tab eventKey={ct.title + ct.id} title={ct.title}>
                      <div className="row">
                        <Common idd={ct.id} title={ct.title} />
                      </div>
                    </Tab>


                  )
                })}






              </Tabs>

            </div>
          </div>
        </section>
      </main >
    );
  }
}
const mapStateToProps = state => {
  return {
    books: state.books.books,
    com: state.comm.commonSync,
    category: state.books.category.categories,
    subCategory: state.books.category.sub_categories,
    secondSubCategory: state.books.category.second_sub_categories,
    thirdSubCategory: state.books.category.third_sub_categories,
    bookSubCategory: state.books.category.book_sub_categories,
    bookSecondSubCategory: state.books.category.book_second_sub_categories,
    bookThirdSubCategory: state.books.category.book_third_sub_categories,
    com: state.comm.commonSync,
  };
};
export default connect(mapStateToProps)(BookSaleCover);
