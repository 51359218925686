import React, { Component } from 'react';

class Category extends Component {
    state = {}
    render() {
        return (
            <div className="container">
                <div className="author-header">
                    <h2>Category</h2>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus inventore eius iure, nostrum velit dolorem aliquid fuga veniam quas, ab cupiditate libero tempora modi non nemo id, quidem architecto autem.</p>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="category-item">
                            <h4>Category name</h4>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Category;