import React, { Component } from "react";
import { MDBSelect } from "mdbreact";

class Select extends Component {
    state = {
        options: [
            {
                text: "USA",
                value: "1"
            },
            {
                text: "Germany",
                value: "2"
            },
            {
                text: "France",
                value: "3"
            },
            {
                text: "Poland",
                value: "4"
            },
            {
                text: "Japan",
                value: "5"
            }
        ]
    };
    componentDidMount() {
        if (this.props.options) {
            this.setState(
                {
                    options: this.props.options
                }
            )
        }
    }
    render() {
        return (
            <div>
                <MDBSelect
                    search
                    options={this.props.options ? this.props.options : this.state.options}
                    selected={this.props.placeHolder}
                    getValue={this.props.getValue}
                    outline
                />
            </div>
        );
    }
}

export default Select;