import React, { Component } from 'react';

import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBFormInline, MDBDropdownItem, MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader, MDBInput, MDBIcon, MDBBtn } from 'mdbreact'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class BookSortSubject extends Component {
    state = {
        collapseID: "collapse2"
    }
    toggleCollapse = collapseID => {
        console.log(collapseID)
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }))
    }

    selectBook = () => {
        let bookSecondSubCategory = this.props.bookSecondSubCategory.filter(bs => bs.second_sub_category_id == this.props.cId)
        let books = []

        bookSecondSubCategory.forEach(bssc => {

            let book = this.props.books.find(bk => {
                return bk.id == bssc.book_id
            })
            books.push(book)
        })

        return books
    }
    subCategory = e => {
        let btsc = this.props.bookSecondSubCategory.filter(bts => bts.second_sub_category_id === e.target.value)
        let books = []
        btsc.forEach(bt => {
            let book = this.selectBook().find(bk => bk.id == bt.book_id)
            books.push(book)
        })

        this.setState({
            filterJob: books
        })
    }
    changeSelectValue = e => {
        if (e.target.value === "lowToHight") {
            let filBooks = this.selectBook()
            filBooks.sort(function (a, b) {
                return a.amount - b.amount
            })
            this.setState({
                filterJob: filBooks
            })
        }
        if (e.target.value === "highToLow") {
            let filBooks = this.selectBook()
            filBooks.sort(function (a, b) {
                return b.amount - a.amount
            })
            this.setState({
                filterJob: filBooks
            })
        }
        if (e.target.value === "newArrival") {
            let filBooks = this.selectBook()
            filBooks.sort(function (a, b) {
                return a.created_at - b.created_at
            })
            this.setState({
                filterJob: filBooks
            })
        }
    }
    changeSelectValueAttribute = e => {
        let bookAttributes = this.props.bookAttributes.filter(bs => bs.attribute_item_id == e.target.value)
        let books = []
        bookAttributes.forEach(bt => {
            let book = this.selectBook().find(bk => bk.id == bt.book_id)
            books.push(book)
        })
        this.setState({
            filterJob: books
        })
    }
    render() {
        const { collapseID } = this.state
        let filterSubCategory = this.props.subCategory.filter(scg => scg.title !== this.props.cName && scg.category_id === "1")
        let bookSecondSubCategory = this.props.bookSecondSubCategory.filter(bs => bs.second_sub_category_id == this.props.cId)
        let title = this.props.secondSubCategory.find(ssc => ssc.id == this.props.cId)
        let books = []

        bookSecondSubCategory.forEach(bssc => {

            let book = this.props.books.find(bk => {
                return bk.id == bssc.book_id
            })
            books.push(book)
        })
        let attributeItem
        if (this.props.attributeItem) {
            attributeItem = this.props.attributeItem.filter(abi => abi.attribute_id == "1")
        }
        return (
            <div className="category-link-area">
                <div className="row">


                </div>
                <div className={this.state.filterBooks ? "d-none" : "d"}>
                    <div className="row">
                        <div className="col-lg-3 booksoring-filter">
                            <div className="bk-srting-area">
                                <h5>sorting area</h5>
                                <small>Book > Author > {title.title}</small>
                            </div>

                            <select class="browser-default custom-select" onChange={this.changeSelectValue}>
                                <option selected>Sort</option>
                                <option value="bestSell">Best Seller</option>
                                <option value="newArrival">New Arrival</option>
                                <option value="lowToHight">Low To High</option>
                                <option value="highToLow">High To Low</option>
                            </select>
                            <select class="browser-default custom-select" onChange={this.changeSelectValueAttribute}>
                                <option selected>Language</option>
                                {attributeItem && attributeItem.map(abi =>
                                    <option value={abi.id}>{abi.title}</option>
                                )}
                            </select>
                            {filterSubCategory && filterSubCategory.map((si, c) => {
                                let subFilter = this.props.secondSubCategory.filter(ssc => {
                                    return ssc.sub_category_id == si.id
                                })
                                let options = []
                                subFilter.forEach(sf => {
                                    let dt = {
                                        text: sf.title,
                                        value: sf.value
                                    }
                                    options.push(dt)
                                })
                                return (
                                    <MDBCard className='mt-3'>
                                        <MDBCollapseHeader
                                            tagClassName='d-flex justify-content-between'
                                            onClick={() => this.toggleCollapse('collapse' + c)}
                                        >
                                            {si.title}
                                            <MDBIcon icon={collapseID === 'collapse' + c ? 'angle-up' : 'angle-down'} />
                                        </MDBCollapseHeader>
                                        <MDBCollapse id={'collapse' + c} isOpen={collapseID}>
                                            <MDBCardBody>
                                                {options && options.map((op, i) => {
                                                    console.log(op)
                                                    return (
                                                        <MDBInput label={op.text} name={si.title} type="radio" id={"checkbox2" + si.title + i} value={op.value} onChange={this.subCategory} />
                                                    )
                                                })}
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                )
                            })}


                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-4">

                                </div>
                            </div>


                            <div className={this.state.filterJob ? "d-none" : "d"}>
                                <div className="row">

                                    {books && books.map(bk => {
                                        let { title, image, amount } = bk
                                        return (
                                            <div className="col-lg-3">
                                                <div class="new-grid simpleCart_shelfItem z-depth-1">
                                                    <div class="new-top">
                                                        <a href="">
                                                            <img
                                                                // src="https://salegarden.com/upload/frontend/product_image_file/0201802110832508.jpg"
                                                                src={"https://anytuition.com/anytuition/public/storage/" + image}
                                                                class="img-responsive"
                                                                alt=""
                                                            />
                                                        </a>
                                                        <div class="new-text">
                                                            <ul>
                                                                <li>
                                                                    <a class="item_add" href="#" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        this.addToCard("book.id")
                                                                    }} >
                                                                        {" "}
                                                                        Add to cart
        </a>
                                                                </li>
                                                                <li>
                                                                    <Link to={"/book_sale/item/details/" + "book.id"}>
                                                                        Show Details
        </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="new-bottom">
                                                        <h5>
                                                            <a class="name" href="">
                                                                {title}
                                                            </a>
                                                        </h5>
                                                        <ul>
                                                            <li>Author : {"book.author"}</li>
                                                            <li>Publisher : {"book.publisher"}</li>
                                                        </ul>
                                                        <div class="ofr">
                                                            <p>
                                                                <span class="item_price">Tk {amount}</span>
                                                            </p>
                                                            <div class="rating">
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>


                            </div>


                            <div className={!this.state.filterJob ? "d-none" : "d"}>
                                <div className="row">
                                    {this.state.filterJob && this.state.filterJob.map(bk => {
                                        let { title, image, amount } = bk
                                        return (
                                            <div className="col-lg-3">
                                                <div class="new-grid simpleCart_shelfItem z-depth-1">
                                                    <div class="new-top">
                                                        <a href="">
                                                            <img
                                                                // src="https://salegarden.com/upload/frontend/product_image_file/0201802110832508.jpg"
                                                                src={"https://anytuition.com/anytuition/public/storage/" + image}
                                                                class="img-responsive"
                                                                alt=""
                                                            />
                                                        </a>
                                                        <div class="new-text">
                                                            <ul>
                                                                <li>
                                                                    <a class="item_add" href="#" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        this.addToCard("book.id")
                                                                    }} >
                                                                        {" "}
                                                                        Add to cart
        </a>
                                                                </li>
                                                                <li>
                                                                    <Link to={"/book_sale/item/details/" + "book.id"}>
                                                                        Show Details
        </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="new-bottom">
                                                        <h5>
                                                            <a class="name" href="">
                                                                {title}
                                                            </a>
                                                        </h5>
                                                        <ul>
                                                            <li>Author : {"book.author"}</li>
                                                            <li>Publisher : {"book.publisher"}</li>
                                                        </ul>
                                                        <div class="ofr">
                                                            <p>
                                                                <span class="item_price">Tk {amount}</span>
                                                            </p>
                                                            <div class="rating">
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                                <span>☆</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>



                        </div>

                    </div>
                </div>



            </div>


        );
    }
}
const mapStateToProps = state => {
    return {
        books: state.books.books,
        category: state.books.category.categories,
        subCategory: state.books.category.sub_categories,
        secondSubCategory: state.books.category.second_sub_categories,
        thirdSubCategory: state.books.category.third_sub_categories,
        bookSubCategory: state.books.category.book_sub_categories,
        bookSecondSubCategory: state.books.category.book_second_sub_categories,
        bookThirdSubCategory: state.books.category.book_third_sub_categories,
        com: state.comm.commonSync,
        attributeItem: state.books.category.attribute_items,
        bookAttributes: state.books.category.book_attributes
    };
};
export default connect(mapStateToProps)(BookSortSubject);