import React, { Component } from 'react';

class Publisher extends Component {
    state = {}
    render() {
        return (
            <section className="section-padding-50">
                <div className="container">
                    <div className="author-header">
                        <h2>Publisher</h2>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus inventore eius iure, nostrum velit dolorem aliquid fuga veniam quas, ab cupiditate libero tempora modi non nemo id, quidem architecto autem.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="author-item">
                                <div className="author-thumb">
                                    <i class="fas fa-book"></i>
                                </div>
                                <h4>Publisher</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Publisher;