import React, { Component } from 'react';

import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBFormInline, MDBDropdownItem, MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader, MDBInput, MDBIcon, MDBBtn } from 'mdbreact'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class BookSortHome extends Component {
    state = {
        collapseID: "collapse2"
    }
    toggleCollapse = collapseID => {
        console.log(collapseID)
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }))
    }

    render() {
        const { collapseID } = this.state
        console.log(this.props.books)
        let books = this.props.books.filter(bk => bk.category_id === "1")
        return (
            <div className="category-link-area">
                <div className="row">


                </div>
                <div className={this.state.filterBooks ? "d-none" : "d"}>
                    <div className="row">
                        {books && books.map(bk => {
                            let { title, image, amount } = bk
                            return (
                                <div className="col-lg-2">
                                    <div class="new-grid simpleCart_shelfItem z-depth-1">
                                        <div class="new-top">
                                            <a href="">
                                                <img
                                                    // src="https://salegarden.com/upload/frontend/product_image_file/0201802110832508.jpg"
                                                    src={"https://anytuition.com/anytuition/public/storage/" + image}
                                                    class="img-responsive"
                                                    alt=""
                                                />
                                            </a>
                                            <div class="new-text">
                                                <ul>
                                                    <li>
                                                        <a class="item_add" href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            this.addToCard("book.id")
                                                        }} >
                                                            {" "}
                                                            Add to cart
        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={"/book_sale/item/details/" + "book.id"}>
                                                            Show Details
        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="new-bottom">
                                            <h5>
                                                <a class="name" href="">
                                                    {title}
                                                </a>
                                            </h5>
                                            <ul>
                                                <li>Author : {"book.author"}</li>
                                                <li>Publisher : {"book.publisher"}</li>
                                            </ul>
                                            <div class="ofr">
                                                <p>
                                                    <span class="item_price">Tk {amount}</span>
                                                </p>
                                                <div class="rating">
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>



            </div>

        );
    }
}
const mapStateToProps = state => {
    return {
        books: state.books.books,
        category: state.books.category.categories,
        subCategory: state.books.category.sub_categories,
        secondSubCategory: state.books.category.second_sub_categories,
        com: state.comm.commonSync,
    };
};
export default connect(mapStateToProps)(BookSortHome);