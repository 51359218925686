import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import SubCommon from "./SubCommon"
import { addCart } from "../../store/actions/eventAction"
import { MDBDropdown, MDBSelect, MDBDropdownToggle, MDBDropdownMenu, MDBFormInline, MDBDropdownItem, MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader, MDBInput, MDBIcon, MDBBtn } from 'mdbreact'
class Common extends Component {
    state = {
        secondSubCategory: null,
        subCategory: null,
        subBookCheck: false,
        subBooks: []
    }
    selectBook = () => {
        let books = this.props.books.filter(bk => bk.category_id == this.props.idd)

        return books
    }
    changeSelectValue = (e, sub) => {
        if (e.target.value.startsWith("ssc")) {
            let id = e.target.value.replace("ssc", "")
            let subCategoryBooks = this.props.bookSubCategory.filter(bsc => bsc.sub_category_id == id)
            let books = []

            subCategoryBooks.forEach(scs => {
                let book = this.props.books.find(bk => bk.id == scs.book_id)
                if (book) {
                    books.push(book)
                }

            })
            this.setState({
                subBookCheck: true,
                subBooks: books,
                secondSubCategory: null
            })
            console.log(books)
            return
        }
        this.setState({
            secondSubCategory: e.target.value,
            subCategory: sub,
            subBookCheck: false,
            subBooks: []
        })
    }

    searchHandler = (event) => {
        var updatedList = this.selectBook();
        updatedList = updatedList.filter(function (item) {
            return item.title.toLowerCase().search(
                event.target.value.toLowerCase()) !== -1;
        });
        this.setState({
            subBookCheck: true,
            subBooks: updatedList,
            secondSubCategory: null
        })
    }
    addToCard = val => {
        this.props.addCart(val)
        alert("submitted")
    }
    render() {

        let books = this.props.books.filter(bk => bk.category_id == this.props.idd)
        let filterSubCategory
        if (this.props.subCategory) {
            filterSubCategory = this.props.subCategory.filter(sc => sc.category_id == this.props.idd)
        }
        return (
            <div className="w-100">

                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            {filterSubCategory && filterSubCategory.map(sc => {
                                let subFilter = this.props.secondSubCategory.filter(ssc => {
                                    return ssc.sub_category_id == sc.id
                                })
                                let options = []
                                subFilter.forEach(sf => {
                                    let dt = {
                                        text: sf.title,
                                        value: sf.id
                                    }
                                    options.push(dt)
                                })
                                return (
                                    <div className="col-md-4">
                                        <select class="browser-default custom-select" onChange={(e) => this.changeSelectValue(e, sc.id)}>
                                            <option selected value={"ssc" + sc.id}>{sc.title}</option>
                                            {options.map(op => {
                                                return (
                                                    <option value={op.value}>{op.text}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <MDBInput hint="Search" type="text" containerClass="mt-0" onChange={this.searchHandler} />
                    </div>
                </div>


                {!this.state.secondSubCategory && !this.state.subBookCheck && (
                    <div className="row">
                        {books && books.map(bk => {
                            let subCategory = this.props.bookSecondSubCategory.filter(sbk => sbk.book_id == bk.id)
                            let subCates = []
                            subCategory.forEach(sc => {
                                let sub = this.props.secondSubCategory.find(ssc => ssc.id == sc.second_sub_category_id)
                                subCates.push(sub)
                            })
                            let { title, image, amount } = bk
                            return (
                                <div className="col-lg-2">
                                    <div class="new-grid simpleCart_shelfItem z-depth-1">
                                        <div class="new-top">
                                            <a href="">
                                                <img
                                                    // src="https://salegarden.com/upload/frontend/product_image_file/0201802110832508.jpg"
                                                    src={"https://anytuition.com/anytuition/public/storage/" + image}
                                                    class="img-responsive"
                                                    alt=""
                                                />
                                            </a>
                                            <div class="new-text">
                                                <ul>
                                                    <li>
                                                        <a class="item_add" href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            this.addToCard(bk.id)
                                                        }} >
                                                            {" "}
                                                            Add to cart
        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={"/book_sale/item/details/" + bk.id}>
                                                            Show Details
        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="new-bottom">
                                            <h5>
                                                <a class="name" href="">
                                                    {title}
                                                </a>
                                            </h5>
                                            <ul>
                                                {subCates && subCates.map(sc => {

                                                    let scg = this.props.subCategory.find(sct => sct.id == sc.sub_category_id)
                                                    return (
                                                        <li><small>{scg.title} : {sc.title}</small> </li>
                                                    )
                                                })}

                                                {/* <li>Publisher : {"book.publisher"}</li> */}
                                            </ul>
                                            <div class="ofr">
                                                <p>
                                                    <span class="item_price">Tk {amount}</span>
                                                </p>
                                                <div class="rating">
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                )}

                {!this.state.secondSubCategory && this.state.subBookCheck && (
                    <div className="row">
                        {this.state.subBooks && this.state.subBooks.map(bk => {
                            let subCategory = this.props.bookSecondSubCategory.filter(sbk => sbk.book_id == bk.id)
                            let subCates = []
                            subCategory.forEach(sc => {
                                let sub = this.props.secondSubCategory.find(ssc => ssc.id == sc.second_sub_category_id)
                                subCates.push(sub)
                            })
                            let { title, image, amount } = bk
                            return (
                                <div className="col-lg-2">
                                    <div class="new-grid simpleCart_shelfItem z-depth-1">
                                        <div class="new-top">
                                            <a href="">
                                                <img
                                                    // src="https://salegarden.com/upload/frontend/product_image_file/0201802110832508.jpg"
                                                    src={"https://anytuition.com/anytuition/public/storage/" + image}
                                                    class="img-responsive"
                                                    alt=""
                                                />
                                            </a>
                                            <div class="new-text">
                                                <ul>
                                                    <li>
                                                        <a class="item_add" href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            this.addToCard(bk.id)
                                                        }} >
                                                            {" "}
                                                            Add to cart
        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={"/book_sale/item/details/" + bk.id}>
                                                            Show Details
        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="new-bottom">
                                            <h5>
                                                <a class="name" href="">
                                                    {title}
                                                </a>
                                            </h5>
                                            <ul>
                                                {subCates && subCates.map(sc => {

                                                    let scg = this.props.subCategory.find(sct => sct.id == sc.sub_category_id)
                                                    return (
                                                        <li><small>{scg.title} : {sc.title}</small> </li>
                                                    )
                                                })}
                                            </ul>
                                            <div class="ofr">
                                                <p>
                                                    <span class="item_price">Tk {amount}</span>
                                                </p>
                                                <div class="rating">
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                    <span>☆</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                )}
                {this.state.secondSubCategory && (<SubCommon cId={this.state.secondSubCategory} subCategory_id={this.state.subCategory} category_id={this.props.idd} />)}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        books: state.books.books,
        category: state.books.category.categories,
        subCategory: state.books.category.sub_categories,
        secondSubCategory: state.books.category.second_sub_categories,
        thirdSubCategory: state.books.category.third_sub_categories,
        bookSubCategory: state.books.category.book_sub_categories,
        bookSecondSubCategory: state.books.category.book_second_sub_categories,
        bookThirdSubCategory: state.books.category.book_third_sub_categories,
        com: state.comm.commonSync,
        attributeItem: state.books.category.attribute_items,
        bookAttributes: state.books.category.book_attributes
    };
};
export default connect(mapStateToProps, { addCart })(Common);