import React, { Component } from 'react';

import { MDBDropdown, MDBSelect, MDBDropdownToggle, MDBDropdownMenu, MDBFormInline, MDBDropdownItem, MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader, MDBInput, MDBIcon, MDBBtn } from 'mdbreact'
import { connect } from 'react-redux';
import { Link, Route, withRouter } from "react-router-dom";
import { addCart } from "../../store/actions/eventAction"
import Book from "../../assets/images/book.jpg"
import Select from "../../Components/Common/Select"
import BookSortHome from "../BookSort/BookSortHome"
import BookSortSubject from "../BookSort/BookSortSubject"
import BookSortAuthor from "../BookSort/BookSortAuthor"
import BookSortPublication from "../BookSort/BookSortPublication"
class BookTab extends Component {
    state = {
        subCategory: false,
        filterBooks: false,
        classes: [],
        collapseID: "collapse2",
        componentName: "",
        componentId: "",
        options: [
            {
                checked: false,
                value: "1",
                text: "Option 1"
            },
            {
                checked: false,
                value: "2",
                text: "Option 2"
            },
            {
                checked: false,
                value: "3",
                text: "Option 3"
            }
        ]
    }

    changeSelectValue = (e, title) => {
        this.setState({
            componentName: title,
            componentId: e.target.value
        })
        console.log(this.state)
    };

    render() {
        console.log(this.props.subCategory)
        let filterSubCategory
        if (this.props.subCategory) {
            filterSubCategory = this.props.subCategory.filter(sc => sc.category_id === "1")
        }
        console.log(filterSubCategory)
        return (
            <div>
                <div className="category-sorting-area mb-4">
                    <div className="row mb-4">
                        <div className="col-md-9">
                            <div className="row">
                                {filterSubCategory && filterSubCategory.map(sc => {
                                    let subFilter = this.props.secondSubCategory.filter(ssc => {
                                        return ssc.sub_category_id == sc.id
                                    })
                                    let options = []
                                    subFilter.forEach(sf => {
                                        let dt = {
                                            text: sf.title,
                                            value: sf.id
                                        }
                                        options.push(dt)
                                    })
                                    return (
                                        <div className="col-md-4">
                                            <select class="browser-default custom-select" onChange={(e) => this.changeSelectValue(e, sc.title)}>
                                                <option selected>{sc.title}</option>
                                                {options.map(op => {
                                                    return (
                                                        <option value={op.value}>{op.text}</option>
                                                    )
                                                })}

                                            </select>
                                        </div>
                                    )
                                })}
                                <div className="col-md-3">
                                    {/* <MDBSelect selected={"sc.title"} getValue={this.changeSelectValue} options={this.state.options} /> */}
                                </div>
                                {/* <div className="col-md-3">
                                    <Select placeHolder="Price" label="Authors" />
                                </div> */}
                            </div>
                        </div>

                        <div className="col-md-3">
                            <MDBInput hint="Search" type="text" containerClass="mt-0" />
                        </div>
                        {/*
                        <Link to="/book_sale/author" className="sblink">authors   </Link>
                        <Link to="/book_sale/category" className="sblink">category   </Link>
                        <Link to="/book_sale/publisher" className="sblink">publisher</Link> */}
                    </div>

                    {this.state.componentName === "" ? <BookSortHome /> : null}
                    {this.state.componentName === "Subject" ? <BookSortSubject cName={this.state.componentName} cId={this.state.componentId} /> : null}
                    {this.state.componentName === "Publication" ? <BookSortPublication cName={this.state.componentName} cId={this.state.componentId} /> : null}
                    {this.state.componentName === "Author" ? <BookSortAuthor cName={this.state.componentName} cId={this.state.componentId} /> : null}
                </div>
            </div>

        );
    }
}
const mapStateToProps = state => {
    return {
        books: state.books.books,
        category: state.books.category.categories,
        subCategory: state.books.category.sub_categories,
        secondSubCategory: state.books.category.second_sub_categories,
        com: state.comm.commonSync,
    };
};
export default withRouter(connect(mapStateToProps, { addCart })(BookTab));